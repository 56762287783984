import React from "react"
import Layout from "../components/Layout"

export default function Bereiche() {
  return (
    <Layout>
      <div className="content flex">
        <div className="area">
          <h2>Strafverteidigung</h2>
          <ul>
            <li>Allgemeines Strafrecht</li>
            <li>Berg- und Skiunf&auml;lle</li>
            <li>Bew&auml;hrungswiderrufe</li>
            <li>Bet&auml;ubungsmittelrecht / Arzneimittelrecht</li>
            <li>Heranwachsenden- und Jugendstrafrecht</li>
            <li>Kapitaldelikte</li>
            <li>Ma&szlig;nahmen der Sicherung und Besserung</li>
            <li>Nebenklage / Jugendschutz und Opferschutz</li>
            <li>Organisierte Kriminalit&auml;t</li>
            <li>Ordnungswidrigkeiten</li>
            <li>Steuerstrafrecht</li>
            <li>Strafvollstreckung</li>
            <li>Strafvollzug</li>
            <li>U-Haft</li>
            <li>Unterbringung</li>
            <li>Verkehrsstrafrecht</li>
            <li>Wirtschaftsstrafrecht</li>
          </ul>
        </div>
        <div className="area">
          <h2>Zivilrecht</h2>
          <ul>
            <li>Allgemeines Zivilrecht</li>
            <li>Mahnwesen</li>
            <li>Vertragsrecht / -gestaltung</li>
            <li>Zwangsvollstreckungen</li>
          </ul>
        </div>
        <div className="area">
          <h2>Grundst&uuml;cks- &amp; Immobilienrecht</h2>
          <a href="/immobilienrecht" target="_blank">...mehr</a>
        </div>
        <div className="area">
          <h2>Arbeitsrecht </h2>
          <ul>
            <li>Arbeitssicherheit</li>
            <li>Arbeitsvertr&auml;ge</li>
            <li>Betriebsvereinbarungen</li>
            <li>Kollektives Arbeitsrecht</li>
            <li>K&uuml;ndigungen und Mahnungen</li>
            <li>Tarifverhandlungen</li>
            <li>Unterst&uuml;tzung und Beratung im operativen Bereich</li>
          </ul>
        </div>
        <div className="area">
          <h2>Verkehrsrecht</h2>
          <ul>
            <li>F&uuml;hrerscheinrecht</li>
            <li>Ordnungswidrigkeiten und Straftaten</li>
            <li>Unfall- und Haftungsrecht</li>
          </ul>
        </div>
        <div className="area">
          <h2>Erbrecht</h2>
          <ul>
            <li>
              Erbfolge und Nachlassplanung durch Testament
              <a href="/erbfolge" target="_blank">
                ...mehr
              </a>
            </li>
            <li>
              Pflichtteilsrecht / Pflichtteilsverzicht
              <a href="/pflichtteil" target="_blank">
                ...mehr
              </a>
            </li>
            <li>
              Auseinandersetzung einer Erbengemeinschaft
              <a href="/auseinandersetzung" target="_blank">
                ...mehr
              </a>
            </li>
            <li>
              Vertretung vor dem Finanzamt oder dem Nachlassgericht /
              Schwarzgeldkonten
              <a href="/nachlassgericht" target="_blank">
                ...mehr
              </a>
            </li>
            <li>
              Verm&ouml;gens&uuml;bergabe zu Lebzeiten
              <a href="/uebergabe" target="_blank">
                ...mehr
              </a>
            </li>
            <li>
              Erbschein
              <a href="/erbschein" target="_blank">
                ...mehr
              </a>
              <b></b>
            </li>
            <li>
              Vorsorgevollmacht, Betreuungsverf&uuml;gung,
              Patientenverf&uuml;gung
              <a href="/vorsorge" target="_blank">
                ...mehr
              </a>
            </li>
          </ul>
        </div>
        <div className="area">
          <h2>Alpinrecht</h2>
            <a href="http://www.alpinrecht.eu" target="_blank" rel="noreferrer">
              ...mehr
            </a>
        </div>
      </div>
    </Layout>
  )
}
